import React from "react";
import styled from "styled-components";
import { selectColor } from "../../../utils/themeUtils";

const SVG = styled.svg`
  width: 8px;
  height: 17px;
`;

export interface LinkedInIconProps {
  style?: React.CSSProperties;
  className?: string;
  color?: any;
}

const LinkedInIcon: React.FC<LinkedInIconProps> = ({
  style,
  className,
  color = "black",
}) => (
  <SVG viewBox="0 0 256 256" style={style} className={className} color={color}>
    <mask id="mask">
      <rect width="256" height="256" fill="white" />
      <path
        d="M77.8 214.24V98.99H39.49v115.25H77.8zM58.65 83.25c13.36 0 21.67-8.85 21.67-19.9-.25-11.32-8.31-19.92-21.42-19.92-13.1 0-21.67 8.6-21.67 19.91 0 11.06 8.31 19.91 21.17 19.91h.25zM99 214.24h38.3v-64.36c0-3.44.25-6.89 1.27-9.34 2.77-6.89 9.07-14.02 19.65-14.02 13.86 0 19.4 10.57 19.4 26.07v61.65h38.31v-66.08c0-35.4-18.9-51.88-44.1-51.88-20.66 0-29.73 11.55-34.78 19.42h.26V98.99H99c.5 10.81-.01 115.25-.01 115.25z"
        fill="black"
      />
    </mask>
    <g fill="none" mask="url(#mask)">
      <path
        d="M0 18.34C0 8.22 8.47 0 18.92 0h218.16C247.53 0 256 8.22 256 18.34v219.32c0 10.13-8.47 18.34-18.92 18.34H18.92C8.47 256 0 247.8 0 237.67V18.34z"
        fill={color}
      />
    </g>
  </SVG>
);

export default LinkedInIcon;
