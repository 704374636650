import NextLink from "next/link";
import React from "react";
import styled from "styled-components";
import { MenuId } from "../../../types/global-types";
import mediaQueries from "../../../utils/mediaQueries";
import {
  iff,
  selectColor,
  selectFont,
  selectSize,
} from "../../../utils/themeUtils";
import FacebookIcon from "../../icons/components/FacebookIcon";
import InstagramIcon from "../../icons/components/InstagramIcon";
import LinkedInIcon from "../../icons/components/LinkedInIcon";
import MastercardIcon from "../../icons/components/MastercardIcon";
import PaypalIcon from "../../icons/components/PaypalIcon";
import PostFinanceIcon from "../../icons/components/PostFinanceIcon";
import RechnungIcon from "../../icons/components/RechnungIcon";
import TwintIcon from "../../icons/components/TwintIcon";

import VisaIcon from "../../icons/components/VisaIcon";
import YoutubeIcon from "../../icons/components/YoutubeIcon";
import PageColumn from "../../layout/components/PageColumn";
import useMenuPages from "../../navigation/hooks/useMenuPages";
import makeNavItemFromPage from "../../navigation/utils/makeNavItemFromPage";

const externalLinks = [
  { href: "https://blog.veloplus.ch/", label: "Veloplus-Blog" },
  { href: "https://occasionen.veloplus.ch", label: "Qualitäts-Occasionen" },
  { href: "http://abo.veloplus.ch", label: "Veloplus-abo" },
  { href: "http://cumpan.ch", label: "Cumpan by Veloplus" },
  { href: "https://flizzi.ch", label: "Flizzi.ch" },
];

const socialLinks = [
  { href: "https://www.facebook.com/veloplus", IconComponent: FacebookIcon },
  { href: "https://www.instagram.com/veloplus", IconComponent: InstagramIcon },
  { href: "https://www.youtube.com/user/VeloPlus", IconComponent: YoutubeIcon },

  {
    href: "https://www.linkedin.com/company/veloplus-ag",
    IconComponent: LinkedInIcon,
  },
];

const paymentMethods = [
  {
    mobileWidth: 113,
    mobileHeight: 15,
    desktopWidth: 83,
    desktopHeight: 11,
    IconComponent: RechnungIcon,
  },
  {
    mobileWidth: 67,
    mobileHeight: 19,
    desktopWidth: 49,
    desktopHeight: 14,
    IconComponent: PaypalIcon,
  },
  {
    mobileWidth: 52,
    mobileHeight: 15,
    desktopWidth: 38,
    desktopHeight: 11,
    IconComponent: VisaIcon,
  },
  {
    mobileWidth: 45,
    mobileHeight: 36,
    desktopWidth: 34,
    desktopHeight: 27,
    IconComponent: MastercardIcon,
  },
  {
    mobileWidth: 79,
    mobileHeight: 26,
    desktopWidth: 58,
    desktopHeight: 19,
    IconComponent: TwintIcon,
  },
  {
    mobileWidth: 87,
    mobileHeight: 16,
    desktopWidth: 65,
    desktopHeight: 12,
    IconComponent: PostFinanceIcon,
  },
];

const Base = styled.div`
  background: ${selectColor("dark")};
  padding-bottom: 60px;
`;

const Inner = styled(PageColumn)`
  display: flex;
  flex-direction: column;

  color: ${selectColor("white")};

  ${mediaQueries.desktop`
    padding: ${selectSize("desktopPagePadding")}px;
    flex-direction: row;
    justify-content: space-between;
  `}
`;

const Column = styled.div<{ desktopOnly?: boolean }>`
  ${iff((p) => p.desktopOnly)`
    display: none;
  `}

  width: 100%;
  border-bottom: 1px solid ${selectColor("white")};
  padding: 25px 0;
  margin-right: ${selectSize("desktopGridGutter")}px;

  &:last-child {
    margin-right: 0;
    border-bottom: none;
  }

  ${mediaQueries.desktop`
    display: block;
    border-bottom: none;
    padding: 0;
  `}
`;

const LinkList = styled.div<{ uppercase?: boolean }>`
  display: flex;
  align-items: flex-start;
  flex-direction: column;

  ${iff((p) => p.uppercase)`
      text-transform: uppercase;
  `}
`;

const Link = styled.a`
  text-decoration: none;
  color: ${selectColor("white")};
  padding: 10px 0;
  ${selectFont("footerSecondary")}

  ${mediaQueries.desktop`
    padding: 4px 0;
  `}

  &:first-child {
    padding-top: 0;
  }

  &:last-child {
    padding-bottom: 0;
  }
  &:hover {
    text-decoration: underline;
  }
`;

const SocialLinkList = styled.div`
  height: 40px;
  margin-left: -12px;

  ${mediaQueries.desktop`
    margin-top: -3px;
    height: 20px;
  `}
`;

const SocialLink = styled.a`
  display: inline-flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  padding: 8px;
  margin-right: 16px;

  ${mediaQueries.desktop`
    width: 30px;
    height: 30px;
    padding: 5px;
    margin-right: 15px;
  `}

  &:last-child {
    margin-right: 0;
  }
`;

const paymentMethodMarginMobile = 25;
const paymentMethodMarginDesktop = 20;

const PaymentMethodList = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  max-width: 275px;
  margin-top: ${-paymentMethodMarginMobile}px;

  ${mediaQueries.desktop`
    max-width: 200px;
    margin-top: ${-paymentMethodMarginDesktop}px;
  `}
`;

const PaymentMethod = styled.div<{
  mobileWidth: number;
  mobileHeight: number;
  desktopWidth: number;
  desktopHeight: number;
}>`
  display: inline-flex;
  justify-content: center;
  align-items: center;
  width: ${(p) => p.mobileWidth}px;
  height: ${(p) => p.mobileHeight}px;
  margin-top: ${paymentMethodMarginMobile}px;

  ${mediaQueries.desktop`
    width: ${(p) => p.desktopWidth}px;
    height: ${(p) => p.desktopHeight}px;
    margin-top: ${paymentMethodMarginDesktop}px;
  `}
`;

export interface SecondaryFooterProps {
  style?: React.CSSProperties;
  className?: string;
}

const iconAttributes = {
  color: "white",
  style: { width: "auto", height: "100%" },
};

const SecondaryFooter: React.FC<SecondaryFooterProps> = ({
  style,
  className,
}) => {
  const footerPages = useMenuPages({ menuId: MenuId.FOOTER })?.pages ?? [];
  return (
    <Base style={style} className={className}>
      <Inner>
        <Column>
          <LinkList uppercase>
            {externalLinks.map(({ href, label }, index) => (
              <Link key={index} href={href} target="_blank">
                {label}
              </Link>
            ))}
          </LinkList>
        </Column>
        <Column>
          <SocialLinkList>
            {socialLinks.map(({ href, IconComponent }) => (
              <SocialLink key={href} href={href} target="_blank">
                <IconComponent {...iconAttributes} />
              </SocialLink>
            ))}
          </SocialLinkList>
        </Column>
        <Column>
          <PaymentMethodList>
            {paymentMethods.map(
              (
                {
                  mobileWidth,
                  mobileHeight,
                  desktopWidth,
                  desktopHeight,
                  IconComponent,
                },
                index,
              ) => (
                <PaymentMethod
                  key={index}
                  mobileWidth={mobileWidth}
                  mobileHeight={mobileHeight}
                  desktopWidth={desktopWidth}
                  desktopHeight={desktopHeight}
                >
                  <IconComponent {...iconAttributes} />
                </PaymentMethod>
              ),
            )}
          </PaymentMethodList>
        </Column>
        <Column desktopOnly>&nbsp;</Column>
        <Column desktopOnly>&nbsp;</Column>
        <Column>
          <LinkList>
            {footerPages
              .map(makeNavItemFromPage)
              .map(({ path, href, title }) => (
                <NextLink key={path} href={href} as={path} passHref>
                  <Link>{title}</Link>
                </NextLink>
              ))}
          </LinkList>
        </Column>
      </Inner>
    </Base>
  );
};

export default React.memo(SecondaryFooter);
