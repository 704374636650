import React from "react";
import styled from "styled-components";
import { selectColor } from "../../../utils/themeUtils";
import NewsletterFooter from "../../footer/components/NewsletterFooter";
import PrimaryFooter from "../../footer/components/PrimaryFooter";
import SecondaryFooter from "../../footer/components/SecondaryFooter";

const FooterWrapper = styled.div`
  background-color: ${selectColor("grey")};
`;

const Footer = React.memo(() => {
  return (
    <FooterWrapper>
      <NewsletterFooter />
      <PrimaryFooter />
      <SecondaryFooter />
    </FooterWrapper>
  );
});

export default Footer;
