import React from "react";
import styled from "styled-components";
import { selectColor } from "../../../utils/themeUtils";

const SVG = styled.svg`
  width: 20px;
  height: 19px;
  path {
    fill: ${selectColor((p) => p.color)};
  }
`;

export interface InstagramIconProps {
  style?: React.CSSProperties;
  className?: string;
  color?: any;
}

const InstagramIcon: React.FC<InstagramIconProps> = ({
  style,
  className,
  color = "black",
}) => (
  <SVG viewBox="0 0 20 19" style={style} className={className} color={color}>
    <defs>
      <path id="a" d="M0 0h30v29H0z" />
    </defs>
    <g transform="translate(-5 -5)" fill="none" fillRule="evenodd">
      <mask id="b" fill="#fff">
        <use xlinkHref="#a" />
      </mask>
      <path
        d="M22.75006 18.0105c0 2.08506-1.74873 3.77546-3.90629 3.77546h-7.50553c-2.157 0-3.90573-1.6904-3.90573-3.77547v-7.25628c0-2.08507 1.74872-3.77547 3.90573-3.77547h7.50553c2.15756 0 3.90629 1.6904 3.90629 3.77547v7.25628zm1.78136-7.2563c0-3.03641-2.54647-5.49742-5.68765-5.49742h-7.50553c-3.14118 0-5.6871 2.461-5.6871 5.49743v7.25628c0 3.03589 2.54592 5.49743 5.6871 5.49743h7.50553c3.14118 0 5.68765-2.46154 5.68765-5.49743v-7.25628zm-9.40805 6.63275c-1.64749 0-2.98739-1.29467-2.98739-2.88668 0-1.59255 1.3399-2.88722 2.98739-2.88722 1.64638 0 2.98628 1.29467 2.98628 2.88722 0 1.592-1.3399 2.88668-2.98628 2.88668zm0-7.39425c-2.57137 0-4.6631 2.02196-4.6631 4.50757 0 2.48507 2.09173 4.50703 4.6631 4.50703 2.57081 0 4.66253-2.02196 4.66253-4.50703 0-2.48561-2.09172-4.50757-4.66253-4.50757zm4.93472-1.37543c-.61352 0-1.11031.48022-1.11031 1.07275 0 .59252.49679 1.07275 1.1103 1.07275.61298 0 1.10977-.48023 1.10977-1.07275 0-.59253-.4968-1.07275-1.10976-1.07275z"
        fill="#FFF"
        mask="url(#b)"
      />
    </g>
  </SVG>
);

export default InstagramIcon;
