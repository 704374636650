import React from "react";
import styled from "styled-components";
import { selectColor } from "../../../utils/themeUtils";

const SVG = styled.svg`
  width: 68px;
  height: 9px;
  path {
    fill: ${selectColor((p) => p.color)};
  }
`;

export interface RechnungIconProps {
  style?: React.CSSProperties;
  className?: string;
  color?: any;
}

const RechnungIcon: React.FC<RechnungIconProps> = ({
  style,
  className,
  color = "black",
}) => (
  <SVG viewBox="0 0 68 9" style={style} className={className} color={color}>
    <path
      d="M0 0v1.9L7 5l7-3.1V0H0zm10 4.8L14 8V3l-4 1.8zM0 8l4-3.2L0 3v5zm7-1.8L4.8 5 0 9h14L9.2 5 7 6.2z"
      fillRule="evenodd"
      clipRule="evenodd"
      fill="#fff"
    />
    <path
      fill="#fff"
      d="M20.8 5.3v2.5c0 .1 0 .2-.2.2H20c-.1 0-.2-.1-.2-.2V1.4c0-.1.1-.2.2-.2h2c1.7 0 2.3.7 2.3 1.5v1c0 .6-.3 1.2-1.2 1.4l1.2 2.6c0 .1 0 .2-.2.2h-.6c-.1 0-.2-.1-.2-.2l-1.1-2.5h-1.4zm0-3.3v2.4H22c.9 0 1.3-.3 1.3-.9v-.6c0-.5-.4-.9-1.3-.9h-1.2zM26.2 8c-.1 0-.2-.1-.2-.2V1.4c0-.1.1-.2.2-.2h3.4c.1 0 .2 0 .2.2v.5c0 .1-.1.1-.2.1H27v2h2.4c.1 0 .2.1.2.2v.4c0 .1-.1.2-.2.2H27v2.3h2.7c.1 0 .2 0 .2.2v.5c0 .2-.1.2-.2.2h-3.5zM35.8 6.5c0 .8-.5 1.5-2.2 1.5s-2.2-.7-2.2-1.5V2.7c0-.8.5-1.5 2.2-1.5s2.2.7 2.2 1.5v.5c0 .1 0 .2-.2.2H35c-.1 0-.2-.1-.2-.2v-.4c0-.5-.3-.9-1.2-.9-.9 0-1.2.3-1.2.9v3.6c0 .5.3.9 1.2.9.9 0 1.2-.3 1.2-.9v-.5c0-.1 0-.2.2-.2h.6c.1 0 .2.1.2.2v.6zM38.3 4h2.6V1.4c0-.1 0-.2.2-.2h.6c.1 0 .2.1.2.2v6.4c0 .1 0 .2-.2.2H41c-.1 0-.2-.1-.2-.2V4.9h-2.6v2.9c0 .1 0 .2-.2.2h-.6c-.1 0-.2-.1-.2-.2V1.4c0-.1.1-.2.2-.2h.6c.1 0 .2.1.2.2V4zM48.3 7.8c0 .1 0 .2-.2.2h-.5c-.1 0-.1 0-.2-.1L45 3.8c-.2-.4-.3-.7-.4-.7v4.7c0 .1 0 .2-.2.2h-.6c-.1 0-.2-.1-.2-.2V1.4c0-.1.1-.2.2-.2h.6c.1 0 .1 0 .2.1L47 5.5l.3.6V1.4c0-.1.1-.2.2-.2h.5c.1 0 .2.1.2.2v6.4zM51.1 6.4c0 .5.4.9 1.3.9s1.3-.3 1.3-.9v-5c0-.1 0-.2.2-.2h.6c.1 0 .2.1.2.2v5.1c0 .8-.6 1.5-2.3 1.5-1.7 0-2.3-.7-2.3-1.5V1.4c0-.1.1-.2.2-.2h.6c.1 0 .2.1.2.2v5zM61 7.8c0 .1 0 .2-.2.2h-.5c-.1 0-.1 0-.2-.1l-2.3-4.1c-.2-.4-.3-.7-.4-.7v4.7c0 .1 0 .2-.2.2h-.6c-.1 0-.2-.1-.2-.2V1.4c0-.1.1-.2.2-.2h.6c.1 0 .1 0 .2.1l2.4 4.2.3.6V1.4c0-.1.1-.2.2-.2h.5c.2 0 .2.1.2.2v6.4zM67 4.6c.1 0 .2 0 .2.2v1.8c0 .8-.5 1.5-2.2 1.5s-2.2-.7-2.2-1.5V2.7c0-.8.5-1.5 2.2-1.5s2.2.7 2.2 1.5v.4c0 .1-.1.2-.2.2h-.6c-.1 0-.2-.1-.2-.2v-.3c.1-.5-.3-.8-1.2-.8-.9 0-1.2.3-1.2.9v3.6c0 .5.3.9 1.2.9.9 0 1.2-.3 1.2-.9v-1H65c-.1 0-.2 0-.2-.2v-.5c0-.1.1-.2.2-.2h2z"
    />
  </SVG>
);

export default RechnungIcon;
