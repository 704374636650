import React from "react";
import styled from "styled-components";
import { selectColor } from "../../../utils/themeUtils";

const SVG = styled.svg`
  width: 17px;
  height: 12px;
  path {
    fill: ${selectColor((p) => p.color)};
  }
`;

export interface YoutubeIconProps {
  style?: React.CSSProperties;
  className?: string;
  color?: any;
}

const YoutubeIcon: React.FC<YoutubeIconProps> = ({
  style,
  className,
  color = "black",
}) => (
  <SVG viewBox="0 0 17 12" style={style} className={className} color={color}>
    <path
      d="M10.94403 6.22841l-3.82787 2.1175c-.16632.0905-.31627-.03142-.31627-.2215V3.77815c0-.19274.15418-.31413.32102-.21883l3.85427 2.22877c.17002.09744.14098.34608-.03115.44032zM17 3.73183C17 1.67078 15.34266 0 13.29884 0h-9.5982C1.65733 0 0 1.67078 0 3.73183v4.53634C0 10.32922 1.65734 12 3.70063 12h9.5982C15.34267 12 17 10.32922 17 8.26817V3.73183z"
      fill="#FFF"
      fillRule="evenodd"
    />
  </SVG>
);

export default YoutubeIcon;
