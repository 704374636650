import React from "react";
import styled from "styled-components";
import mediaQueries from "../../../utils/mediaQueries";
import { reactPageGridWidths } from "../../../utils/reactPageGridWidths";
import { selectColor, selectSize } from "../../../utils/themeUtils";
import Content from "../../content/components/Content";
import useBaseConfig from "../../core/hooks/useBaseConfig";
import PageColumn from "../../layout/components/PageColumn";

const footerColumnSelector = "> div > .react-page-row >";

const Base = styled(PageColumn)`
  display: flex;
  flex-direction: column;
  color: ${selectColor("white")};
  padding-bottom: 5px;

  ${footerColumnSelector} .react-page-cell {
    flex-basis: 100%;
    width: 100%;
    min-width: 100%;
  }

  ${mediaQueries.desktop`
    padding: 8px ${selectSize("desktopPagePadding")}px;
    padding-bottom: 90px;

    ${footerColumnSelector} .react-page-cell {
      min-width: 0;
    }

    ${reactPageGridWidths("sm", footerColumnSelector)}
  `}
`;

export interface PrimaryFooterProps {}

const PrimaryFooter: React.FC<PrimaryFooterProps> = () => {
  const { data } = useBaseConfig();

  const content = data?.config?.footer?.content;

  return (
    <Base>
      {content ? (
        <Content
          content={content}
          configSelection={{ resource: "Config", area: "footer" }}
        />
      ) : null}
    </Base>
  );
};

export default React.memo(PrimaryFooter);
