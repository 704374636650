import React from "react";
import styled from "styled-components";
import { selectColor } from "../../../utils/themeUtils";

const SVG = styled.svg`
  width: 8px;
  height: 17px;
  path {
    fill: ${selectColor((p) => p.color)};
  }
`;

export interface FacebookIconProps {
  style?: React.CSSProperties;
  className?: string;
  color?: any;
}

const FacebookIcon: React.FC<FacebookIconProps> = ({
  style,
  className,
  color = "black",
}) => (
  <SVG viewBox="0 0 8 17" style={style} className={className} color={color}>
    <path
      d="M1.76304 8.56703v8.2189c0 .11847.09736.21407.21683.21407h3.09138c.11999 0 .21682-.0956.21682-.21407V8.43297h2.2409c.11316 0 .20684-.0847.21631-.19588l.21525-2.5169c.01105-.1247-.08894-.23174-.21578-.23174H5.28807V3.7026c0-.41827.34366-.75756.76732-.75756h1.72726c.11999 0 .21735-.09613.21735-.21408V.21407C8 .09612 7.90264 0 7.78265 0H4.86442C3.15137 0 1.76304 1.37068 1.76304 3.06195v2.4265H.21683c-.11947 0-.21683.0956-.21683.21407v2.5169c0 .11795.09736.21355.21683.21355h1.54621v.13406z"
      fill="#FFF"
      fillRule="evenodd"
    />
  </SVG>
);

export default FacebookIcon;
