import React from "react";
import styled from "styled-components";
import { selectColor } from "../../../utils/themeUtils";

const SVG = styled.svg`
  width: 58px;
  height: 19px;
  path {
    fill: ${selectColor((p) => p.color)};
  }
`;

export interface TwintIconProps {
  style?: React.CSSProperties;
  className?: string;
  color?: any;
}

const TwintIcon: React.FC<TwintIconProps> = ({
  style,
  className,
  color = "black",
}) => (
  <SVG viewBox="0 0 58 19" style={style} className={className} color={color}>
    <path
      d="M39.92208 14.06h1.88311V5.7h-1.88311v8.36zm11.2987-8.36v1.58351h2.43051V14.06h1.9182V7.28351H58V5.7h-6.77922zm-15.73824 4.38958l-.06928.47738-.09238-.47738L33.8197 5.7h-.60046l-1.50117 4.38958-.09238.47738-.05773-.47738L30.44787 5.7h-1.8245l2.35568 8.36h.75058l1.73211-4.68067.05774-.37259.05774.3726L35.30933 14.06h.75058l2.35567-8.36h-1.81294l-1.1201 4.38958zM46.87801 5.32c-2.04644 0-3.1897 1.35641-3.1897 3.30256V14.06h1.82922V8.58718c0-.84923.48017-1.50974 1.38335-1.50974.90318 0 1.37191.77846 1.37191 1.50974V14.06h1.8178V8.62256C50.11344 6.66462 48.93588 5.32 46.878 5.32zM13.93506 9.49153h-1.68383v-.0239l-1.94108 2.91649-.99393-1.55387 1.14594-1.75707c.21048-.31077.66652-1.17137.14032-2.34275C10.18152 5.77421 9.25776 5.32 8.43923 5.32c-.81853 0-1.69553.4303-2.16326 1.41043-.53789 1.13552-.07016 2.00808.12863 2.3069 0 0 .64313.95622 1.16932 1.75706l.8653 1.27895 1.29796 2.03198c.0117.01195.21048.33468.57297.33468.3391 0 .56128-.32273.58466-.34663l3.04025-4.60184zM7.9091 12.53005l-.8841-1.28921-.72878 1.03853L4.3488 9.54576s-.51374-.78785-.86021-1.33696c-.35842-.58493.03584-1.46828.8602-1.46828.10753 0 .19116.01194.28674.03582l.681-1.2534C5.0059 5.39161 4.65943 5.32 4.3488 5.32c-.82437 0-1.73236.42974-2.21026 1.40859-.54957 1.13403-.07168 2.00544.13143 2.30387l3.40498 5.04942c.03584.03582.25089.35812.6093.35812.35843 0 .57348-.31037.59737-.34618l1.02747-1.56377zm8.66234-7.40278v8.74546c0 .24953-.17705.5466-.3895.66542L8.6752 18.91088c-.21245.11883-.56654.11883-.779 0L.3895 14.53815C.17705 14.41932 0 14.11038 0 13.87273V5.12727c0-.24953.17705-.5466.3895-.66542L7.8962.08912c.21246-.11883.56655-.11883.779 0l7.50672 4.37273c.21245.11883.3895.42777.3895.66542zM20.71429 5.7h6.77922l-.0119 1.58351h-2.42624V14.06h-1.91484V7.28351H20.7143V5.7z"
      fill="#FFF"
      fillRule="evenodd"
    />
  </SVG>
);

export default TwintIcon;
