import { css } from "styled-components";
import { range } from "lodash-es";

const gridSize = 12;
const precision = Math.pow(10, 8);

export const reactPageGridWidths = (
  viewport: ReactPageViewport,
  childSelector: string = "",
) => css`
  ${range(1, gridSize).map((count) => {
    const width = Math.round((count / gridSize) * 100 * precision) / precision;

    return `
      ${childSelector} .react-page-cell-${viewport}-${count} {
        flex-basis: ${width}%;
        width: ${width}%;
        ${count % 3 === 0 ? `min-width: ${width}%;` : ""}
      }
    `;
  })}
`;

type ReactPageViewport = "xs" | "sm" | "md" | "lg";
