import React from "react";
import styled from "styled-components";
import { selectColor } from "../../../utils/themeUtils";
import Content from "../../content/components/Content";

import PageColumn from "../../layout/components/PageColumn";
import useBaseConfig from "../../core/hooks/useBaseConfig";

const Base = styled.div`
  background: ${selectColor("dark")};
`;

const Inner = styled(PageColumn)`
  display: flex;
  flex-direction: column;
  color: ${selectColor("white")};
  padding-top: 20px;
  padding-bottom: 25px;
`;

export interface NewsletterFooterProps {
  style?: React.CSSProperties;
  className?: string;
}

const NewsletterFooter: React.FC<NewsletterFooterProps> = ({
  style,
  className,
}) => {
  const { data } = useBaseConfig();

  const newsletter = data?.config?.footer?.newsletter;

  return (
    <Base style={style} className={className}>
      <Inner>
        {newsletter ? (
          <Content
            content={newsletter}
            configSelection={{ resource: "Config", area: "footerNewsletter" }}
          />
        ) : null}
      </Inner>
    </Base>
  );
};

export default React.memo(NewsletterFooter);
