import React from "react";
import styled from "styled-components";
import { selectColor } from "../../../utils/themeUtils";

const SVG = styled.svg`
  width: 38px;
  height: 11px;
  path {
    fill: ${selectColor((p) => p.color)};
  }
`;

export interface VisaIconProps {
  style?: React.CSSProperties;
  className?: string;
  color?: any;
}

const VisaIcon: React.FC<VisaIconProps> = ({
  style,
  className,
  color = "black",
}) => (
  <SVG viewBox="0 0 38 11" style={style} className={className} color={color}>
    <defs>
      <path id="a" d="M0 0h38v11H0z" />
    </defs>
    <g fill="none" fillRule="evenodd">
      <mask id="b" fill="#fff">
        <use xlinkHref="#a" />
      </mask>
      <path
        d="M10.80671.19273L7.79473 7.45992l-.3211-1.47666v-.00041L6.3947 1.0859C6.20868.41154 5.66834.20985 5.00026.18621H.03927L0 .40828c1.2067.27545 2.28607.67355 3.23166 1.16862L5.967 10.83253l3.25496-.00326L14.06556.19273H10.8067zm20.90952 6.87113c.25463-.61405 1.22568-2.97859 1.22568-2.97859-.01813.02812.25247-.6169.40784-1.01704l.20802.91843s.5891 2.54423.71253 3.0772h-2.55407zm3.794-6.86909h-2.37757c-.7367 0-1.2874.19029-1.61108.88461l-4.56911 9.76578h3.23036s.52868-1.31286.6478-1.60135c.35303 0 3.49146.0049 3.9403.0049.09193.37283.37418 1.59686.37418 1.59686L38 10.84517 35.51023.19476zM27.62055.4474C27.01116.23144 26.05565 0 24.8632 0c-3.04003-.0004-5.18152 1.44529-5.19965 3.51685-.01683 1.53127 1.52908 2.38532 2.69564 2.89547 1.19762.52156 1.60029.85487 1.59424 1.32183-.0069.71388-.95594 1.04067-1.84024 1.04067-1.2313 0-1.88557-.16177-2.8959-.55986l-.39661-.1695-.43158 2.38571c.71858.29746 2.0474.55538 3.42673.56883 3.23424-.0004 5.33387-1.42858 5.3576-3.64072.01166-1.21263-.80834-2.13473-2.58299-2.89547-1.07506-.49344-1.73408-.82186-1.72717-1.32101 0-.44333.5576-.9168 1.76213-.9168 1.00601-.01508 1.73494.19232 2.30247.40828l.27578.12265.4169-2.30953zM13.38711 10.8419L15.31066.185h3.07628l-1.92483 10.6569h-3.075z"
        fill="#FFF"
        mask="url(#b)"
      />
    </g>
  </SVG>
);

export default VisaIcon;
